






























































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {BusinessContact, messageType, senderType} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import ContactsList from '@/components/ContactsList.vue';
import {profileStore} from '@/store/modules/profile';
import GpHeader from '@/components/custom/HeaderView.vue';
import GpTabs from '@/components/custom/TabsView.vue';
import GpTab from '@/components/custom/TabView.vue';

import {chatStore} from "@/store/modules/chat/ChatStore";
import { chat, newChatMessage } from '@/data/firebase';
import { setDoc, arrayUnion, Timestamp, updateDoc } from 'firebase/firestore';

@Component({
  name: 'dialog-create-new-group-chat',
  components: {ContactsList, BaseDialog,  GpHeader, GpTabs, GpTab},
})
export default class DialogCreateNewGroupChat extends Vue {
  @Prop() value!: boolean;
  @Prop({required: false}) customer: any
  
  userId?: string | null = profileStore.t2bUser?.id
  showClose: boolean = true;
  showList: boolean = false;
  groupName: string = '';
  selectedTab: number = 0;
  tabs: string[] = ['Customers', 'Directory', 'My Contacts'] 
  
  assignedDirectoryList: BusinessContact[] = []
  assignedContactsList: BusinessContact[] = []
  

  selectedDirectory: BusinessContact[] = []
  selectedContacts: BusinessContact[] = []
  selectedCustomers: BusinessContact[] = []
  selectedPersonals: BusinessContact[] = []

  selectedAdminsDistinct: BusinessContact[] = []
  selectedCustomersDistinct: BusinessContact[] = []  

  selectedListAll: BusinessContact[] = []

  currentStep: string = 'select-customer'
  stepCustomers: string = 'select-customer'
  stepContacts: string = 'select-contact'

  @Getter businessDirectory

  
  @Getter chatsBusy
  @Getter optedInCustomers;
  @Getter customers;
  @Getter selectedTextSession
  

  @Action loadCustomers;
  @Action createActiveChat
  @Action addChatMembers 
  
 
  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get title() {
    return !this.showList ? 'Create a chat' : 'Selected contact list';
  }

  get subtitle() { 
    if(this.showList) {
      return "";
    }
    
    return this.currentStep === this.stepCustomers
        ? 'Select more than one contact to create a new group chat'
        : 'Create the chat on behalf of the contact'
  }

  
  get customerContacts(){
    return   
  }

  /*get assignedContacts() {
    this.assignedContactsList = profileStore.assignedContacts
    return this.assignedContactsList;
  }*/

  get getAssignedDirectoryList() {
    return this.assignedDirectoryList
  }

  get getAssignedContactsList() {
    return this.assignedContactsList
  }

  get personalCustomers() {
    const id = profileStore.t2bUser!.id;
    return this.customers.filter((customer) => customer.personal && customer.personal.includes(id));
  }

  get toalCount(){
    let count = 0;   

    this.onAdminContactsDistinct();
    if(this.selectedAdminsDistinct.length > 0){
      count += this.selectedAdminsDistinct.length;
    } 

    this.onCustomerContactsDistinct();
    if(this.selectedCustomersDistinct.length > 0){
      count += this.selectedCustomersDistinct.length;
    } 

    return count;
  }

  onDirectorySelected(contacts: any[]) {       
    this.assignedContactsList = []     
    setTimeout(() => {
      this.assignedContactsList = profileStore.assignedContacts;  
    }, 500)
    
    this.selectedDirectory = contacts;
    this.selectedContacts = [];    
  }

  onContactSelected(contacts: any[]) {  
    this.assignedDirectoryList = []     
    setTimeout(() => {
      this.assignedDirectoryList = profileStore.assignedContacts;  
    }, 500)

    this.selectedDirectory = [];    
    this.selectedContacts = contacts;    
  }

  onCustomerSelected(contacts: any[]) {
    this.selectedCustomers = contacts;
  }  

  onPersonalSelected(contacts: any[]){
    this.selectedPersonals = contacts;
  } 

  onAdminContactsDistinct() {
    this.selectedAdminsDistinct = [];
    
    const tempContact = this.selectedContacts.filter(el => {
        return !this.selectedDirectory.find(element => {
          return element.id == el.id;
        });
    });    
    
    this.selectedAdminsDistinct = [...this.selectedDirectory, ...tempContact]
  }

  onCustomerContactsDistinct() {
    this.selectedCustomersDistinct = [];
    
    const tempContact = this.selectedPersonals.filter(el => {
        return !this.selectedCustomers.find(element => {
          return element.id == el.id;
        });
    });    
    
    this.selectedCustomersDistinct = [...this.selectedCustomers, ...tempContact]
  }

  onShowList(){
    this.showClose = false;
    this.showList = true;
    this.onAdminContactsDistinct();
    this.onCustomerContactsDistinct();
    this.selectedListAll =  [...this.selectedAdminsDistinct, ...this.selectedCustomersDistinct]    
  }
  
  onCloseList(){
    this.showClose = true;
    this.showList = false;
  }

  onTabChanged(index: number) {    
    this.selectedTab = index;
  }

  async navigateToChat(chat){

    try{
      const chatId = chat.id
      const isRequest = chat.case?.status === 1

      if (isRequest) {        
        await this.$router.push({
          name: 'inbox-request',
          params: {chatId},
          query: {type: 'inbox', subtype: 'personal'}
        });
      } else {        
        await this.$router.push({
          name: 'active-chat',
          params: {chatId},
          query: {type: 'active', subtype: 'personal', newChat: 'true'}
        });
      }

    }catch(err){  
      console.log(err)
    }

    return true;
  }
  
  async onCreateChat() {
    
    // get distinct contacts
    this.onAdminContactsDistinct();
    this.onCustomerContactsDistinct();

    // create single chat
    let chat: any = await this.createActiveChat({
      customerId: this.selectedCustomersDistinct[0].id,
      contactId: this.selectedAdminsDistinct[0].id
    });    

    if (!chat) {
      return
    }     
    
    // create group chat
    try{
      // get selectedTextSession
      this.navigateToChat(chat);
      await new Promise(resolve => setTimeout(resolve, 2000));   
      
      // chat title
      if(this.groupName){
        await chatStore.changeChatTitle({chatId: this.selectedTextSession.id, title: this.groupName})
      }

      // add memeber
      await this.onAddChatMembers();
    }catch(err){  
      console.log(err)
    }   
    this.show = false
  }

  async onAddChatMembers() { 
    try{
      // add admin
      if(this.selectedAdminsDistinct.length > 1){
        var admins = this.selectedAdminsDistinct.filter((item) =>{ return item.id != this.selectedAdminsDistinct[0].id });        
        const data = {
          sender: this.sender,
          contactIds: admins.map((item) => item.id),
          title:  this.groupName
        }
        await this.addChatMembers(data);
      }

      // add customer
      if(this.selectedCustomersDistinct.length > 1){
        var customers = this.selectedCustomersDistinct.filter((item) =>{ return item.id != this.selectedCustomersDistinct[0].id });        
        await this.onAddCustomers(customers);
      }      
    }
    catch (error) {
      console.error(error);      
    }   

    this.show = false
  }

  async onAddCustomers(data){
    if(data.length == 0){
        return true
    }
    try {
      return await Promise.all(data.map(async (customer) => {
          try{            
            await setDoc(chat(this.selectedTextSession.id), {memberIDs: arrayUnion(customer.id)}, {merge: true}).then((res) =>{
                setDoc(chat(this.selectedTextSession.id), { members : {
                    [customer.id]: {
                        description: "Customer",
                        id: customer.id,
                        name: customer.fullName,
                        status: customer?.status || null,
                        type: 2,
                        uid: customer.id,
                        isRemoved: false,
                        removedTimestamp: null
                    }
                }}, { merge: true}).then((res) =>{ })
            });

            await setDoc(chat(this.selectedTextSession.id), {subtype: 3}, {merge: true})

          }catch(err){
            console.log('onAddCustomers error');
            console.log(err)
          }

      }));
    } catch (error) {
      console.error(error);
      return true
    }
  }

  get sender() {
      if (this.selectedTextSession.type === 4) { // active chat
          if (this.selectedTextSession.associate.id === this.userId) {
              return {
                  contactId: this.selectedTextSession.associate.id,
                  contactName: this.selectedTextSession.associate.name
              }
          }
      }
      const from = this.selectedTextSession.from;
      if (from.uid === this.userId) {
          return {
              contactId: from.id,
              contactName: from.name
          }
      }
      const to = this.selectedTextSession.to;
      if (to.uid === this.userId) {
          return {
              contactId: to.id,
              contactName: to.name
          }
      }
      const member = this.selectedTextSession.members[this.userId!];
      if (!!member) {
          return {
              contactId: member.id,
              contactName: member.name
          }
      }
      return null
  }

  async created() {
    if (this.customer) {
      this.selectedCustomers.push(this.customer)
      this.currentStep = this.stepContacts
    }

    this.assignedDirectoryList = profileStore.assignedContacts
    this.assignedContactsList = profileStore.assignedContacts
  }
}
